import { delay } from "../delay";

export async function retry(
  callback,
  { attempts = 1, isValid = () => true, ...delayOptions } = {},
) {
  let already = 0;
  let success = false;
  let result = undefined;
  while (already < attempts && !success) {
    try {
      await delay(delayOptions);
      result = await callback();
      success = isValid(result);
    }
    catch {
      success = false;
    }
    already++;
  }

  if (!success)
    throw new Error(`'retry' attempted ${already} times with failure`);

  return result;
}
