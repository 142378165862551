import { createApi } from "@/lib";

export function createwebLoggerEndpoint({ environment }) {
  const webLoggerUrl = environment.get("WEB_WEB_LOGGER_URL");
  if (!webLoggerUrl) return null;

  const api = createApi({ baseUrl: webLoggerUrl, type: "http" });
  const endpoint = api.createEndpoint({ method: "post", url: "/" });
  return endpoint;
}
