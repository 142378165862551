<template>
  <div
    v-if="config"
    class="list-power-search d-flex flex-column flex-md-row justify-content-md-between"
  >
    <div>
      <SearchText
        v-if="config.text"
        v-model="searchParams.text"
      />
    </div>
    <div class="d-flex justify-content-end align-items-end mt-2 mt-md-0">
      <SearchEnum
        v-if="config.enum"
        v-model="searchParams.enum"
        :config="config.enum"
      />
    </div>
  </div>
</template>

<script>
import { stringIncludes } from "@/lib/strings";

import { SearchEnum, SearchText } from "../search";

export default {
  components: { SearchEnum, SearchText },
  data() {
    return {
      searchParams: { enum: [], text: "" },
    };
  },
  methods: {
    satisfySearch(item, params, config) {
      let isTextOk = true;

      const isTextSearchable = config && config.text;
      const wasSomeTextSearched = params.text && params.text.length > 0;

      if (isTextSearchable && wasSomeTextSearched) {
        isTextOk = config.text.find((field) => {
          const itemValue = typeof field === "function" ? field(item) : item[field];
          return stringIncludes(itemValue, params.text);
        });
      }

      let isEnumOk = true;
      const isEnumSearchable = config && config.enum;
      const wasSomeEnumSearched
        = params.enum
        && params.enum.length > 0
        && params.enum.length < config.enum.length;
      if (isEnumSearchable && wasSomeEnumSearched) {
        const index = params.enum.findIndex(isEnum => isEnum(item));
        isEnumOk = index !== -1;
      }

      return isTextOk && isEnumOk;
    },
    updateSearchResult() {
      const searchedItems = this.allItems.filter(item =>
        this.satisfySearch(item, this.searchParams, this.config),
      );

      this.$emit("input", searchedItems);
    },
  },
  name: "ListPowerSearch",
  props: {
    allItems: {
      required: true,
      type: Array,
    },
    config: {
      default: null,
      type: Object,
    },
    value: {
      required: true,
      type: Array,
    },
  },
  watch: {
    allItems: {
      handler: "updateSearchResult",
      immediate: true,
    },
    searchParams: {
      deep: true,
      handler: "updateSearchResult",
    },
  },
};
</script>
