import { createBaseStoreModule } from "@/lib";

export function createChangelogStore({ endpoints }) {
  return createBaseStoreModule({
    actions: {
      async markAllRead({ commit, state }) {
        const allKeys = state.allLogs.map(l => l.key);
        await endpoints.changelogsReadSave.dispatch(allKeys);
        commit("updateReadKeys", allKeys);
      },
      async onTurnOn({ commit }) {
        const logs = await endpoints.changelogsPublished.dispatch();
        commit("updateLogs", logs);

        const readKeys = await endpoints.changelogsReadList.dispatch();
        commit("updateReadKeys", readKeys);
      },
    },
    getters: {
      howManyLogs: ({ allLogs }) => allLogs.length,
      howManyUnread: ({ allLogs, readKeys }) => {
        const unreadLogs = allLogs.filter(l => !readKeys.includes(l.key));
        return unreadLogs.length;
      },
      prettyHowManyUnread(state, { howManyUnread }) {
        if (!howManyUnread || howManyUnread === 0) return null;
        return howManyUnread.toString();
      },
      recentLogs: ({ allLogs }) => howMany => allLogs.slice(0, howMany),
    },
    mutations: {
      updateLogs(state, logs) {
        if (Array.isArray(logs)) {
          state.allLogs = logs;
        }
      },
      updateReadKeys(state, keys) {
        if (Array.isArray(keys)) {
          state.readKeys = keys;
        }
      },
    },
    state: {
      allLogs: [],
      readKeys: [],
    },
  });
}
