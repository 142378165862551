import { encodeClient } from "./encode";

export function createClientsEndpoints({ api, pageLimit }) {
  const clientsUrl = "/v1/clientes";
  return {
    clientAdd: api.createEndpoint({
      encode: frontendClient => ({ payload: encodeClient(frontendClient) }),
      method: "post",
      url: clientsUrl,
    }),

    clientEdit: api.createEndpoint({
      // a transformação do client só é necessárias no post pq o backend exige um formato específico para inclusão de cliente que não se aplica na edição
      encode: frontendClient => ({ payload: frontendClient, url: `${clientsUrl}/${frontendClient.id}` }),
      method: "put",
    }),

    clientsList: api.createEndpoint({
      encode: page => ({ url: `${clientsUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
