import { AppError, SYSLOG_SEVERITIES, incDate } from "@/lib";

export const stateKey = "otp";

export function createInitialState(otpBlockedSeconds) {
  return {
    channels: [],
    message: null,
    otpBlockedSeconds,
    requested: null,
  };
}

export const getters = {
  channelOfType: (state, { channels }) => tipo => channels.find(c => c.tipo === tipo),
  channels: state => state[stateKey].channels,
  hasChannels: (state, { channels }) => channels.length > 0,
  isOtpSent: (state, { otpMessage }) => !!otpMessage,
  otpBlockedUntil: ({ otpBlockedSeconds }, { otpRequested }) => {
    if (!otpRequested) return null;
    return incDate(otpRequested, { seconds: otpBlockedSeconds });
  },
  otpMessage: state => state[stateKey].message,
  otpRequested: state => state[stateKey].requested,

};

export const mutations = {
  setChannels(state, channels = []) {
    channels.sort((a, b) => (a.mask < b.mask ? 1 : -1));
    state[stateKey].channels = channels;
  },

  setOtpMessage(state, otpMessage = null) {
    state[stateKey].message = otpMessage;
  },

  setOtpRequested(state, otpRequested = null) {
    state[stateKey].requested = otpRequested;
  },
};

export function createOtpActions(endpoints) {
  return {
    async challengeUserOtp({ commit, getters }, { otp }) {
      const payload = {
        idConta: getters.idConta,
        otp,
      };
      const loginResponse = await endpoints.otpVerification.dispatch(payload);
      const {
        conta: { codigo, nome, perfis },
        expiracao: expirationAsDateString,
        // registration options é um conjunto de dados usado pelo authenticator do navegador ou sistema operacional para registrar um novo dispositivo web-authn vinculado a essa conta que sucedeu na verificação do otp.
        registrationOptions,
        token,
      } = loginResponse;

      commit("setWebAuthnRegistrationOptions", registrationOptions);
      commit("setToken", token);
      commit("setExpiration", expirationAsDateString);
      commit("setCodigo", codigo);
      commit("setNome", nome);
      commit("setPerfis", perfis);

      if (perfis.length <= 0) {
        AppError.throw({
          message: "A conta deste usuário não possui perfil vinculado",
          severity: SYSLOG_SEVERITIES.WARNING,
        });
      }
    },

    async triggerUserOtp({ commit, getters: { idConta } }, { tipo }) {
      const payload = {
        idConta: idConta,
        tipoCanal: tipo,
      };
      const { message } = await endpoints.otpTrigger.dispatch(payload);

      commit("setOtpRequested", new Date());
      commit("setOtpMessage", message);
    },
  };
}
