import { createBaseStoreModule } from "@/lib";

export function createContasStore({ endpoints }) {
  return createBaseStoreModule({
    actions: {
      search(_, code) {
        return endpoints.accountByCode.dispatch(code);
      },
    },
    getters: {
      getActiveContas: (state, getters, rootState, rootGetters) => {
        const getActiveItemsFromModule = (module) => {
          return rootGetters[`${module}/filterItemsByFieldValue`]("ativo", true);
        };
        const interessados = getActiveItemsFromModule("interessados");
        const gestores = getActiveItemsFromModule("gestores");
        const requisitantes = getActiveItemsFromModule("requisitantes");

        const allActiveContas = gestores.concat(requisitantes, interessados);
        return allActiveContas;
      },

      getUniqueActiveContas: (state, getters) => {
        const allActiveContas = getters.getActiveContas;

        const isSameConta = (a, b) => a.codigo === b.codigo;

        const uniqueContas = allActiveContas.reduce((acc, conta) => {
          const exists = acc.find(c => isSameConta(c, conta));
          if (!exists) acc.push(conta);
          return acc;
        }, []);

        return uniqueContas;
      },
    },
  });
}
