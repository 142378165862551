import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
} from "libphonenumber-js/max";

import { laxParse } from "./lax-parse";
import { needsCountry } from "./needs-country";

export function decodePhone(maybePhone, options) {
  if (!maybePhone || typeof maybePhone !== "string") return undefined;

  const phone = laxParse(maybePhone, options);

  if (phone) {
    const { country } = phone;
    const countryNumber = phone.countryCallingCode;
    const { nationalNumber } = phone;
    const formattedNationalNumber = phone.formatNational();
    const fullNumber = countryNumber + nationalNumber;
    const formattedFullNumber = phone.formatInternational();
    return {
      country,
      countryNumber,
      formattedFullNumber,
      formattedNationalNumber,
      fullNumber,
      nationalNumber,
    };
  }

  if (needsCountry(options?.country)) {
    let baseNumber = maybePhone;
    if (baseNumber.startsWith("+55")) {
      baseNumber = baseNumber.slice(3);
    }
    const countryNumber = "55";
    const nationalNumber = parseIncompletePhoneNumber(baseNumber);
    const formattedNationalNumber = formatIncompletePhoneNumber(
      baseNumber,
      "BR",
    );
    const fullNumber = countryNumber + nationalNumber;
    const formattedFullNumber = formatIncompletePhoneNumber(
      `+55 ${formattedNationalNumber}`,
      "BR",
    );

    return {
      country: "BR",
      countryNumber,
      formattedFullNumber,
      formattedNationalNumber,
      fullNumber,
      nationalNumber,
    };
  }
}
