export function envelopApiEndpoint({ apiEndpoint, decode, encode }) {
  const endpoint = {
    dispatched: false,
    dispatching: false,
  };

  endpoint.dispatch = async (userOptions) => {
    const options = typeof encode === "function" ? encode(userOptions) : userOptions;
    try {
      endpoint.dispatching = true;
      const result = await apiEndpoint.dispatch(options);
      return typeof decode === "function" ? decode(result) : result;
    }
    finally {
      endpoint.dispatched = true;
      endpoint.dispatching = false;
    }
  };

  return endpoint;
}
