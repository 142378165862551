import { createApi } from "@/lib";

export function createAuthEndpoints(backendApi) {
  const key = "sessionData";
  const sessionApi = createApi({ key, type: "localStorage" });
  const sessionRead = sessionApi.createEndpoint({ method: "get" });
  const sessionSave = sessionApi.createEndpoint({ method: "set" });
  const sessionClear = sessionApi.createEndpoint({ method: "remove" });

  const linkAuthentication = backendApi.createEndpoint({
    method: "patch",
    url: "/v1/link",
  });

  const otpVerification = backendApi.createEndpoint({
    method: "patch",
    url: "/v1/verificar-otp",
  });

  const otpTrigger = backendApi.createEndpoint({
    method: "patch",
    url: "/v1/enviar-otp",
  });

  const listAccounts = backendApi.createEndpoint({
    encode: email => ({ url: `/v1/listar-contas/${email}` }),
    method: "get",
  });

  const accountConfirmation = backendApi.createEndpoint({
    encode: idConta => ({ url: `/v1/confirmacao-conta/${idConta}` }),
    method: "get",
  });

  const tokenAcquisition = backendApi.createEndpoint({
    method: "put",
    url: "/v1/token",
  });

  // é usado para buscar opções genéricas de uso de web authn para preenchimento automático de autocomplete de contas já presentes no navegador
  const passkeyOptions = backendApi.createEndpoint({
    method: "get",
    url: "/v1/authentication-options",
  });

  // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir o registro de um novo dispositivo web authn vinculado a uma conta junto ao backend.
  const passkeyRegistration = backendApi.createEndpoint({
    method: "post",
    url: "/v1/verify-registration",
  });

  // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir a autenticação com um dispositivo web authn vinculado anteriormente a essa conta junto ao backend.
  const passkeyVerification = backendApi.createEndpoint({
    method: "post",
    url: "/v1/verify-authentication",
  });

  return {
    accountConfirmation,
    linkAuthentication,
    listAccounts,
    otpTrigger,
    otpVerification,
    passkeyOptions,
    passkeyRegistration,
    passkeyVerification,
    sessionClear,
    sessionRead,
    sessionSave,
    tokenAcquisition,
  };
}
