import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export function createEndpoint({ key, method }) {
  const dispatch = (payload) => {
    switch (method) {
      case "get":
        return JSON.parse(localStorage.getItem(key));
      case "set":
        localStorage.setItem(key, JSON.stringify(payload));
        break;
      case "remove":
        localStorage.removeItem(key);
        break;
      default:
        AppError.throw({ message: `method ${method} not supported`, severity: SYSLOG_SEVERITIES.ERROR });
    }
  };
  return { dispatch };
}
