import { createBaseStoreModule } from "@/lib";

import {
  createLinkAutenticadoActions,
  getters as linkAutenticadoGetters,
  initialState as linkAutenticadoInitialState,
  mutations as linkAutenticadoMutations,
  stateKey as linkAutenticadoStateKey,
} from "./link-autenticado";
import {
  createOtpActions,
  createInitialState as otpCreateInitialState,
  getters as otpGetters,
  mutations as otpMutations,
  stateKey as otpStateKey,
} from "./otp";
import {
  createProfileActions,
  getters as profileGetters,
  initialState as profileInitialState,
  mutations as profileMutations,
  stateKey as profileStateKey,
} from "./profile";
import {
  createSignActions,
  getters as signGetters,
  initialState as signInitialState,
  mutations as signMutations,
  stateKey as signStateKey,
} from "./sign";
import {
  createWebAuthnActions,
  getters as webAuthnGetters,
  initialState as webAuthnInitialState,
  mutations as webAuthnMutations,
  stateKey as webAuthnStateKey,
} from "./web-authn";

export function createAuthStore({ endpoints, otpBlockedSeconds }) {
  const initialState = {
    [linkAutenticadoStateKey]: linkAutenticadoInitialState,
    [otpStateKey]: otpCreateInitialState(otpBlockedSeconds),
    [profileStateKey]: profileInitialState,
    [signStateKey]: signInitialState,
    [webAuthnStateKey]: webAuthnInitialState,
  };

  return createBaseStoreModule({
    actions: {
      ...createLinkAutenticadoActions(endpoints),
      ...createOtpActions(endpoints),
      ...createProfileActions(endpoints),
      ...createSignActions(endpoints),
      ...createWebAuthnActions(endpoints),
    },
    getters: {
      ...linkAutenticadoGetters,
      ...otpGetters,
      ...profileGetters,
      ...webAuthnGetters,
      ...signGetters,
    },
    mutations: {
      ...linkAutenticadoMutations,
      ...otpMutations,
      ...profileMutations,
      ...webAuthnMutations,
      ...signMutations,
    },
    state: initialState,
  });
}
