<template>
  <DescriptionText>
    Por favor, aguarde enquanto tentamos recuperar sua sessão.
  </DescriptionText>
</template>

<script>
import { DescriptionText } from "@/lib";
import { mapActions, mapMutations } from "vuex";

export default {
  components: { DescriptionText },
  methods: {
    ...mapActions("auth", ["attemptAuthenticationFromPreviousSession", "signOut"]),
    ...mapMutations("auth", ["setLinkAutenticado"]),
  },
  async mounted() {
    let authenticatedFromPreviousSession = false;

    if (this.session !== "clean") {
      authenticatedFromPreviousSession = await this.attemptAuthenticationFromPreviousSession();
    }

    if (!authenticatedFromPreviousSession) {
      await this.signOut();
    }

    if (typeof this.linkToken === "string") {
      this.setLinkAutenticado({ id: this.linkId, token: this.linkToken });
    }
  },
  name: "SessionDialog",
  props: {
    linkId: {
      default: null,
      type: String,
    },
    linkToken: {
      default: null,
      type: String,
    },
    session: {
      default: null,
      type: String,
    },
  },
};
</script>
