var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalBase, {
    ref: "modal",
    attrs: { "show-action-divider": "", timer: _setup.timer },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ cancel }) {
            return [
              _c(
                _setup.RowBase,
                [
                  _c(_setup.ColBase, { staticClass: "d-none d-sm-block" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(_setup.AppIcon, {
                          attrs: {
                            name: _vm.icon,
                            variant: _vm.variant,
                            "font-scale": "5",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    _setup.ColBase,
                    { attrs: { cols: "12", sm: "9" } },
                    [
                      _c(
                        "w-text",
                        { staticClass: "message-title" },
                        [
                          _c(_setup.AppIcon, {
                            staticClass: "d-inline-block d-sm-none mr-2",
                            attrs: { name: _vm.icon, variant: _vm.variant },
                          }),
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_setup.messageNormalized))]),
                      _vm._v(" "),
                      _setup.hasDetail
                        ? _c(
                            "ul",
                            { staticClass: "detail" },
                            _vm._l(
                              _setup.messageDetailNormalized,
                              function (part) {
                                return _c("li", { key: part }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(part) +
                                      "\n          "
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(_setup.ButtonClose, {
                on: {
                  click: function ($event) {
                    return cancel()
                  },
                },
              }),
            ]
          },
        },
        {
          key: "actions",
          fn: function ({ save, cancel }) {
            return [_vm._t("default", null, { save: save, cancel: cancel })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }