import { decodeManager, encodeManager } from "./code";

export function createManagersEndpoints({ api, pageLimit }) {
  const managersUrl = "/v1/contas/perfil/GESTOR";
  return {
    managerAdd: api.createEndpoint({
      decode: decodeManager,
      encode: clientManager => ({ payload: encodeManager(clientManager) }),
      method: "post",
      url: managersUrl,
    }),

    managerEdit: api.createEndpoint({
      decode: decodeManager,
      encode: clientManager => ({ payload: encodeManager(clientManager), url: `${managersUrl}/${clientManager.id}` }),
      method: "put",
    }),

    managersList: api.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeManager), pagination }),
      encode: page => ({ url: `${managersUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
