import { createCollectionStoreModule } from "@/lib";

export function createGestoresStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addManager({ commit }, userManager) {
        const responseManager = await endpoints.managerAdd.dispatch(userManager);
        commit("setItem", responseManager);
      },

      async editManager({ commit }, userManager) {
        const responseManager = await endpoints.managerEdit.dispatch(userManager);
        commit("setItem", responseManager);
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.managersList,
  });
}
