import DOMPurify from "dompurify";
import * as matter from "gray-matter";
import { marked } from "marked";

export function markdownToHtml(
  markdownText = "",
  { hasFrontMatter = false } = {},
) {
  const result = {
    html: "",
    markdown: "",
    meta: {},
  };

  if (!markdownText || typeof markdownText !== "string") return result;

  let serializedMarkText = removeExtraInitialSpace(markdownText);
  if (hasFrontMatter) {
    const parsedFrontMatter = matter.default(markdownText);
    result.meta = parsedFrontMatter.data;
    serializedMarkText = parsedFrontMatter.content;
  }
  result.markdown = serializedMarkText;
  result.html = DOMPurify.sanitize(marked.parse(serializedMarkText));

  return result;
}

function removeExtraInitialSpace(str) {
  const lines = str.split("\n");
  const normalized = lines
    .map(line => line.replaceAll("\r", ""))
    .map(line => (line.trim().length === 0 ? "" : line))
    .filter((line, i) => !(line === "" && (i === 0 || i === lines.length - 1)));

  const shortestIndentation = normalized.reduce((shortestSoFar, line) => {
    if (line === "") return shortestSoFar;
    const initialWhiteSpace = line.length - line.trimStart().length;
    return initialWhiteSpace < shortestSoFar
      ? initialWhiteSpace
      : shortestSoFar;
  }, Number.MAX_SAFE_INTEGER);

  const withoutExtraIndentation = normalized.map(line =>
    line ? line.substring(shortestIndentation) : line,
  );

  return withoutExtraIndentation.join("\n");
}
