import { createCollectionStoreModule } from "@/lib";
import Vue from "vue";

import { ALGORITMO_DESKTOP } from "../assinador-desktop";
import { hasPendingAcknowledgement } from "../fluxos";

export function createFluxosExternoStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async obtainHashesByDocument(_, userFlow) {
        const options = { algorithm: ALGORITMO_DESKTOP, flowId: userFlow.id };
        const hashes = await endpoints.flowHashes.dispatch(options);
        return hashes;
      },

      async reject({ commit }, userFlow) {
        const responseFlow = await endpoints.flowReject.dispatch(userFlow.id);
        commit("setItem", responseFlow);
      },

      async reviewDraft({ commit }, { fluxo, resposta }) {
        const options = { feedback: resposta, flowId: fluxo.id };
        const responseFlow = await endpoints.flowReviewDraft.dispatch(options);
        commit("setItem", responseFlow);
        return responseFlow;
      },

      async reviewSubmit({ commit }, { fluxo, resposta }) {
        const options = { feedback: resposta, flowId: fluxo.id };
        const responseFlow = await endpoints.flowReviewSubmit.dispatch(options);
        commit("setItem", responseFlow);
        return responseFlow;
      },

      async signAdvanced({ commit }, userFlow) {
        const responseFlow = await endpoints.flowSignAdvanced.dispatch(userFlow.id);
        commit("setItem", responseFlow);
      },

      async signSerproId({ commit }, { fluxoId, ...rest }) {
        const options = { flowId: fluxoId, ...rest };
        const responseFlow = await endpoints.flowSignSerproId.dispatch(options);
        commit("setItem", responseFlow);
        return responseFlow;
      },

      async signSerproSigner({ commit }, { documentos, fluxo }) {
        const options = { algorithm: ALGORITMO_DESKTOP, documents: documentos, flowId: fluxo.id };
        const responseFlow = await endpoints.flowSignSerproSigner.dispatch(options);
        commit("setItem", responseFlow);
        return responseFlow;
      },
    },
    getters: {
      fluxoAcknowledgments:
      ({ acknowledgments }) =>
        fluxoId =>
          Object.entries(acknowledgments)
            .filter(([, a]) => a.fluxoId === fluxoId)
            .map(([documentoId, a]) => ({ documentoId, ...a })),

      hasFluxoPendingAcknowledgement:
      (state, getters, rootState, rootGetters) =>
        (fluxo) => {
          const fluxoAcknowledgments = getters.fluxoAcknowledgments(fluxo.id);
          const codigo = rootGetters["auth/codigo"];
          return hasPendingAcknowledgement(fluxo, codigo, fluxoAcknowledgments);
        },

      isCodigoExhibitionAcknowledged:
      ({ acknowledgments }) =>
        documentoId =>
          acknowledgments[documentoId]
          && acknowledgments[documentoId].codigoExhibition,

      isContentAcknowledged:
      ({ acknowledgments }) =>
        documentoId =>
          acknowledgments[documentoId] && acknowledgments[documentoId].content,
    },
    mutations: {
      setAcknowledgment({ acknowledgments }, { codigoExhibition, content, documentoId, fluxoId }) {
        Vue.set(acknowledgments, documentoId, {
          codigoExhibition,
          content,
          fluxoId,
        });
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.flowsEngagementList,
    state: {
    // como é ignorado pelo backend e portanto seria apagado durante o polling. não pode ser guardado junto a coleção de itens (documentos) da store.
      acknowledgments: {},
    },
  });
}
