import { createApi } from "@/lib";

import { resolveAll, resolvePublished } from "./resolver";

export function createChangelogEndpoints() {
  const logsApi = createApi({ type: "resolver" });

  const key = "changelogRead";
  const readApi = createApi({ key, type: "localStorage" });

  return {
    changelogsAll: logsApi.createEndpoint({ resolver: resolveAll }),
    changelogsPublished: logsApi.createEndpoint({ resolver: resolvePublished }),
    changelogsReadList: readApi.createEndpoint({ method: "get" }),
    changelogsReadSave: readApi.createEndpoint({ method: "set" }),
  };
}
