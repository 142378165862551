import { formatDate, markdownToHtml, sortByField } from "@/lib";

import * as rawLogs from "./logs";
// recurso do webpack para carregar as imagens que forem referenciadas no markdown de cada arquivo de changelog
// eslint-disable-next-line no-undef
const imagesContext = require.context("./logs", true, /\.(png|jpe?g|gif|svg)$/);

export function resolveAll() {
  return resolve(() => true);
}

export function resolvePublished() {
  return resolve(changelog => !changelog.draft);
}

function resolve(filter) {
  const logs = Object
    .entries(rawLogs)
    .map(([key, rawLog]) => {
      const logWithImage = replaceImagePathInMarkdown(rawLog);
      const { html, meta } = markdownToHtml(logWithImage, { hasFrontMatter: true });
      const datePretty = formatDate(meta.date);
      return { key, ...meta, datePretty, html };
    })
    .filter(filter);
  const sortedLogs = sortByField(logs, { asc: false, field: "date" });
  return sortedLogs;
}

function replaceImagePathInMarkdown(markdown) {
  const regexToCaptureImagesInMarkdown = /\]\((\.\/[^\s)]+)(?:\s"[^"]*")?\)/g;
  return markdown.replace(regexToCaptureImagesInMarkdown, (match, relPath) => {
    // arqui nos usamos o webpack para importar a imagem mencionada no markdown e substituir o caminho original por aquele computado pelo webpack
    const imageUrl = imagesContext(relPath);
    return `](${imageUrl})`;
  });
}
