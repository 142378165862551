import { delay } from "@/lib/async";
import { isEqual } from "@/lib/objects";

export function createQueryFeatures(config) {
  return {
    actions: {
      queryItems: createQueryAction(config),
    },
    getters: {
      hasQueriedOnce: state => state.hasQueriedOnce,
      howManyQuerying(state, getters, rootState) {
        const modules = Object.values(rootState);
        const pendingQueries = modules.reduce((acc, module) => {
          if (!module.isQuerying) return acc;
          return acc + 1;
        }, 0);
        return pendingQueries;
      },
      isQuerying: state => state.isQuerying,
    },
    mutations: {
      startQuerying: (state) => {
        state.isQuerying = true;
      },
      stopQuerying: (state) => {
        state.isQuerying = false;
        if (!state.hasQueriedOnce) {
          state.hasQueriedOnce = true;
        }
      },
    },
    state: {
      // é usada para controlar efeitos colaterais a serem aplicados após que a primeira query completa foi concluída como por exemplo a carga de dados numa tela de cadastro.
      hasQueriedOnce: false,
      isQuerying: false,
    },
  };
}

function createQueryAction(config) {
  const { queryEndpoint } = config;

  return async ({ commit, getters }) => {
    const maxQueries = 5;
    let pending = getters.howManyQuerying;
    let seconds = 1;
    while (pending > maxQueries) {
      // vai adicionando até um segundo a cada interação de forma a não sobrecarregar a linha de processamento principal e distanciar as queries para o momento de requisitar processamento
      seconds = seconds + Math.random();
      await delay({ seconds });
      pending = getters.howManyQuerying;
    }

    try {
      commit("startQuerying");
      const allItems = [];

      let pageNumber = 0;
      let hasItems;
      do {
        const pageItems = await queryPage({ page: pageNumber, queryEndpoint });

        hasItems = pageItems.length > 0;

        if (hasItems) {
          allItems.push(...pageItems);
          pageNumber++;
          // a primeira página é carregadamente imediatamente para dar a sensação de interatividade imediata no sistema. as páginas seguintes são acumuladas para atualização em lote e evitar congelamento de telas por excesso de efeitos colaterais no motor de reatividade do framework
          if (pageNumber === 1) syncOnlySet(allItems, { commit, getters });
        }
      } while (hasItems);

      // enviar as alterações para atualização em uma única instrução para evitar que a atualização de tela aconteça várias vezes em um longo intervalo promovendo congelamento dos controles de tela
      syncAllChanges(allItems, { commit, getters });
    }
    finally {
      commit("stopQuerying");
    }
  };
}

async function queryPage({ page, queryEndpoint }) {
  const result = await queryEndpoint.dispatch(page);
  if (!result || !result.data) return [];
  return result.data;
}

function syncOnlySet(serverItems, { commit, getters }) {
  const manifest = solveItemsToSet(serverItems, { getters });
  commit("updateItems", manifest);
}

function syncAllChanges(serverItems, { commit, getters }) {
  const manifestToSet = solveItemsToSet(serverItems, { getters });

  const clientItems = getters.getAllItems;
  const itemsToDel = clientItems.filter(({ id: clientId }) => {
    const isAbsentOnServer = !serverItems.find(
      ({ id: serverId }) => clientId === serverId,
    );
    return isAbsentOnServer;
  });

  const manifest = itemsToDel
    .map(i => ({ operation: "del", payload: i }))
    .concat(manifestToSet);

  commit("updateItems", manifest);
}

function solveItemsToSet(serverItems, { getters }) {
  const itemsToSet = serverItems.filter((serverItem) => {
    const clientItem = getters.getItemById(serverItem.id);
    const isAbsentOnClient = !clientItem;
    const isDifferentFromClient = !isEqual(serverItem, clientItem);
    return isAbsentOnClient || isDifferentFromClient;
  });
  const manifest = itemsToSet.map(i => ({ operation: "set", payload: i }));
  return manifest;
}
