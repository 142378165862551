import axios from "axios";

export function createClient({ baseUrl, tokenOrGetter }) {
  const client = axios.create({
    baseURL: baseUrl,
    validateStatus: () => true,
  });

  if (tokenOrGetter) {
    client.interceptors.request.use((config) => {
      const token = typeof tokenOrGetter === "function"
        ? tokenOrGetter()
        : tokenOrGetter;
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  return client;
}
