export const stateKey = "linkAutenticado";

export const initialState = {
  id: null,
  token: null,
};

export const getters = {
  hasLinkAutenticado: (state, { linkAutenticadoId, linkAutenticadoToken }) => !!linkAutenticadoToken && !!linkAutenticadoId,
  linkAutenticadoId: state => state[stateKey].id,
  linkAutenticadoToken: state => state[stateKey].token,
};

export const mutations = {
  setLinkAutenticado(state, { id, token }) {
    state[stateKey].token = token;
    state[stateKey].id = id;
  },
};

export function createLinkAutenticadoActions(endpoints) {
  return {
    async authenticateWithLink({ commit, getters }) {
      const payload = {
        idFluxo: getters.linkAutenticadoId,
        token: getters.linkAutenticadoToken,
      };

      const {
        cliente,
        conta,
        expiracao: expirationAsDateString,
        token,
      } = await endpoints.linkAuthentication.dispatch(payload);

      const { codigo, email, id: idConta, nome: nomeUsuario, perfis } = conta;
      const { id: idCliente, nome: nomeCliente, sigla } = cliente;

      commit("setContas", [{ id: idConta, idCliente }]);
      commit("setClientes", [{ id: idCliente, nome: nomeCliente, sigla }]);
      commit("setCodigo", codigo);
      commit("setEmail", email);
      commit("setConta", idConta);
      commit("setCliente", idCliente);
      commit("setNome", nomeUsuario);
      commit("setToken", token);
      commit("setExpiration", expirationAsDateString);
      commit("setPerfis", perfis);
    },
  };
}
