import { isObjectLike } from "@/lib/objects";

import { throwBadRequest } from "./throw";

export function createEndpoint({ client, headers, method = "get", responseType, url: endpointUrl }) {
  const dispatch = async (dispatchOptions) => {
    const { payload, url: dispatchUrl } = (isObjectLike(dispatchOptions) && ("payload" in dispatchOptions || "url" in dispatchOptions))
      ? dispatchOptions
      : { payload: dispatchOptions };
    const url = dispatchUrl ?? endpointUrl;

    let response;
    try {
      response = await client({ data: payload, headers, method, responseType, url });
      if (response.status >= 300) throw new Error(response.statusText);
    }
    catch {
      throwBadRequest({ ...response, method, url });
    }
    return response.data;
  };

  return { dispatch };
}
