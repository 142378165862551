export function stringIncludes(a, b, { bidirectional = false, caseSensitive = false } = {}) {
  if (typeof a !== "string" || typeof b !== "string") return false;

  const aNormalized = caseSensitive ? a : a.toLowerCase();
  const bNormalized = caseSensitive ? b : b.toLowerCase();

  if (bidirectional) return aNormalized.includes(bNormalized) || bNormalized.includes(aNormalized);

  return aNormalized.includes(bNormalized);
}
