import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export function createResolverApi() {
  return {
    createEndpoint: ({ resolver }) => {
      if (typeof resolver !== "function") {
        AppError.throw({ message: "resolver must be a function", severity: SYSLOG_SEVERITIES.ERROR });
      }
      return { dispatch: payload => resolver(payload) };
    },
  };
}
