import { retry } from "@/lib/async";
import { SYSLOG_SEVERITIES } from "@/lib/severities";
import { readonly, ref } from "vue";

export function useExecute({ logger, retry: userRetry = false } = {}) {
  const isBusy = ref(false);
  const lastError = ref(null);
  const lastResult = ref(null);

  const execute = async (saga, payload) => {
    const onExecute = typeof saga === "function" ? saga : saga.onExecute;
    const onExecuteWithPayload = () => {
      return onExecute(payload);
    };

    // sagas relacionadas a carregamento de dados da store não são bem sucedidas em casos onde a página com a instrução fosse a primeira acessada pelo usuário, p.e.: usuário acessa link de editar requisitante. usar tryThenThrow foi o mecanismo encontrado para que a carga de dados em memória fosse concluída.
    const options = {
      attempts: 2,
      isValid: r => !!r,
    };
    const wrappedOnExecute = userRetry
      ? () => retry(onExecuteWithPayload, options)
      : onExecuteWithPayload;

    const { onError, onSuccess } = saga;

    if (isBusy.value) {
      const message = "não é possível executar um comando enquanto outro ainda está em execução";
      logger && logger.log({ message, saga, severity: SYSLOG_SEVERITIES.DEBUG });
      return;
    }

    isBusy.value = true;
    lastError.value = null;
    lastResult.value = null;
    try {
      lastResult.value = await wrappedOnExecute();
      onSuccess && onSuccess(lastResult.value);
    }
    catch (error) {
      lastError.value = error;
      if (onError) {
        onError(lastError.value);
      }
      else if (logger) {
        const message = "error without an explicit callback for treatment was cathced during a managed async execution created by useExecute";
        logger.log({ error, message, severity: SYSLOG_SEVERITIES.INFO });
      }
    }
    finally {
      isBusy.value = false;
    }
  };

  return {
    execute,
    isBusy: readonly(isBusy),
    lastError: readonly(lastError),
    lastResult: readonly(lastResult),
  };
}
