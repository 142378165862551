import "core-js/stable"; // core e regenerator possibilitam o uso das notações async e await em funções assíncronas em conjunto com babel
import { initApisEndpoints } from "@/apis";
import { SYSLOG_SEVERITIES, installComponentsGlobalDependencies, installValidationSuite } from "@/lib";
import "regenerator-runtime/runtime";
import Vue from "vue";

import manifest from "../package.json";
import "./app/design"; // carrega os estilos personalizados e também das bibliotecas bootstrap e bootstrap-vue
import { initLegacyStore } from "@/data";

import { initEnvironment } from "./app/environment";
import { initApp } from "./app/init-app";
import { initLogger } from "./app/logger";
import { initRouter } from "./app/router";

async function main() {
  Vue.config.productionTip = false;

  // injetando propriedades e funções comuns em todos os componentes
  installComponentsGlobalDependencies(Vue);

  const environment = initEnvironment(manifest);
  const endpoints = initApisEndpoints({ environment, getToken });
  const store = await initLegacyStore({ endpoints, environment });
  const logger = initLogger({ environment, globals: { Vue, window }, store, webLoggerEndpoint: endpoints.logger });
  const router = initRouter({ environment, store });
  const app = initApp({ endpoints, environment, logger, router, store });

  // instala componentes, regras e diretivas ligadas à validação de entradas do usuário
  installValidationSuite();

  // expondo globals em window para uso pela suite de testes e funções setup que não conseguem acessar a instância de vue. poderá ser substituído por provide/inject após migração para vue 3.x
  window.$app = app;
  window.$environment = environment;
  window.$store = store;

  app.$mount("#app");

  // contato para ver se o servidor está acessível
  await endpoints.hello.dispatch();

  logger.log({ message: `${manifest.name} v${manifest.version} inicializado`, severity: SYSLOG_SEVERITIES.INFO });

  function getToken() {
    return store.getters["auth/token"];
  }
}

main();
