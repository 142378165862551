import { decodeFlow, encodeFlow } from "./code-flow";

export function createFlowsEndpoints({ api, pageLimit }) {
  const flowsUrl = "/v1/fluxos";

  return {
    flowAdd: api.createEndpoint({
      decode: decodeFlow,
      encode: clientFlow => ({ payload: encodeFlow(clientFlow) }),
      method: "post",
      url: flowsUrl,
    }),

    flowArchive: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/arquivar` }),
      method: "patch",
    }),

    flowCancel: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/cancelar` }),
      method: "patch",
    }),

    flowEdit: api.createEndpoint({
      decode: decodeFlow,
      encode: clientFlow => ({ payload: encodeFlow(clientFlow), url: `${flowsUrl}/${clientFlow.id}` }),
      method: "put",
    }),

    flowFinish: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/finalizar` }),
      method: "patch",
    }),

    flowHashes: api.createEndpoint({
      encode: ({ algorithm, flowId }) => ({ url: `${flowsUrl}/${flowId}/hashes-documentos/${algorithm}` }),
      method: "get",
    }),

    flowLinkSend: api.createEndpoint({
      encode: ({ idFluxo, idRequisitante, interessado }) => ({ payload: { idFluxo, idRequisitante, interessados: [interessado] } }),
      method: "patch",
      url: "/v1/reenviar-links",
    }),

    flowReject: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/rejeitar` }),
      method: "patch",
    }),

    flowReviewDraft: api.createEndpoint({
      encode: ({ feedback, flowId }) => ({ payload: feedback, url: `${flowsUrl}/${flowId}/revisao` }),
      method: "patch",
    }),

    flowReviewSubmit: api.createEndpoint({
      encode: ({ feedback, flowId }) => ({ payload: feedback, url: `${flowsUrl}/${flowId}/revisar` }),
      method: "patch",
    }),

    flowSignAdvanced: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/assinar` }),
      method: "patch",
    }),

    flowSignSerproId: api.createEndpoint({
      decode: decodeFlow,
      encode: ({ flowId, ...payload }) => ({ payload, url: `${flowsUrl}/${flowId}/assinar-serpro-id` }),
      method: "patch",
    }),

    flowSignSerproSigner: api.createEndpoint({
      decode: decodeFlow,
      encode: ({ algorithm, documents, flowId }) => ({ payload: { documentos: documents }, url: `${flowsUrl}/${flowId}/assinar-desktop/${algorithm}` }),
      method: "patch",
    }),

    flowStart: api.createEndpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/iniciar` }),
      method: "patch",
    }),

    flowsArchivedList: api.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `${flowsUrl}/arquivados?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),

    flowsEngagementList: api.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `/v1/respostas?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),

    flowsManagementList: api.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `${flowsUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
