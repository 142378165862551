import { decodeStakeholder, encodeStakeholder } from "./code";

export function createStakeholdersEndpoints({ api, pageLimit }) {
  const stakeholdersUrl = "/v1/contas/perfil/INTERESSADO";

  return {
    stakeholderAdd: api.createEndpoint({
      decode: decodeStakeholder,
      encode: clientStakeholder => ({ payload: encodeStakeholder(clientStakeholder) }),
      method: "post",
      url: stakeholdersUrl,
    }),

    stakeholderEdit: api.createEndpoint({
      decode: decodeStakeholder,
      encode: clientStakeholder => ({ payload: encodeStakeholder(clientStakeholder), url: `${stakeholdersUrl}/${clientStakeholder.id}` }),
      method: "put",
    }),

    stakeholdersList: api.createEndpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeStakeholder), pagination }),
      encode: page => ({ url: `${stakeholdersUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
