import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { createHttpApi } from "../http";
import { createLocalStorageApi } from "../local-storage";
import { createResolverApi } from "../resolver";
import { envelopApiEndpoint } from "./endpoint";

export function createApi({ type, ...apiOptions } = {}) {
  let api;
  switch (type) {
    case "http":
      api = createHttpApi(apiOptions);
      break;
    case "localStorage":
      api = createLocalStorageApi(apiOptions);
      break;
    case "resolver": // usado para mocks ou para servir arquivos estáticos como se viessem de uma requisição http
      api = createResolverApi(apiOptions);
      break;
    default:
      AppError.throw({ message: "api type invalid", severity: SYSLOG_SEVERITIES.ERROR });
  }

  const createEndpoint = ({ decode, encode, ...endpointOptions }) => {
    const apiEndpoint = api.createEndpoint(endpointOptions);
    const envelopedEndpoint = envelopApiEndpoint({ apiEndpoint, decode, encode });
    return envelopedEndpoint;
  };

  return { createEndpoint };
}
