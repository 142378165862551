import { createAccountsEndpoints } from "../accounts";
import { createAuthEndpoints } from "../auth";
import { createBackendApi } from "../backend-api";
import { createChangelogEndpoints } from "../changelog";
import { createClientsEndpoints } from "../clients";
import { createDocumentsEndpoints } from "../documents";
import { createFlowsEndpoints } from "../flows";
import { createGroupsEndpoints } from "../groups";
import { createHelloEndpoint } from "../hello";
import { createwebLoggerEndpoint } from "../logger";
import { createManagersEndpoints } from "../managers";
import { createRequestersEndpoints } from "../requesters";
import { createStakeholdersEndpoints } from "../stakeholders";

export function initApisEndpoints({ environment, getToken }) {
  const pageLimit = environment.get("WEB_PAGINATION_LIMIT", 100);
  const backendApi = createBackendApi({ environment, getToken });

  return {
    hello: createHelloEndpoint(backendApi),
    logger: createwebLoggerEndpoint({ environment }),
    ...createAccountsEndpoints(backendApi),
    ...createAuthEndpoints(backendApi),
    ...createChangelogEndpoints(),
    ...createClientsEndpoints({ api: backendApi, pageLimit }),
    ...createDocumentsEndpoints(backendApi),
    ...createFlowsEndpoints({ api: backendApi, pageLimit }),
    ...createGroupsEndpoints({ api: backendApi, pageLimit }),
    ...createManagersEndpoints({ api: backendApi, pageLimit }),
    ...createRequestersEndpoints({ api: backendApi, pageLimit }),
    ...createStakeholdersEndpoints({ api: backendApi, pageLimit }),
  };
}
