import parsePhoneNumber from "libphonenumber-js/max";

import { needsCountry } from "./needs-country";

export function laxParse(maybePhone, { country } = {}) {
  if (!maybePhone || typeof maybePhone !== "string") return undefined;

  let phone = parsePhoneNumber(maybePhone, country);
  if (phone) return phone;

  if (needsCountry(country)) {
    phone = parsePhoneNumber(maybePhone, "BR");
    if (phone) return phone;
  }

  return undefined;
}
